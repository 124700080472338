import { gettext } from "django-i18n";
import reverse from "django-reverse";
import waffle from "_common/waffle";

const ENABLE_SD_BLUEPRINT = waffle.flag_is_active("ENABLE_SD_BLUEPRINT");

const createRecentFunction =
  ({ getChildren, title, getChildLink, getChildChildren, getMoreLink }) =>
  (obj) => {
    const children = getChildren(obj);

    const items = children.slice(0, 3).map((child) => ({
      label: child.name,
      link: getChildLink(child),
      target: "_self",
      children: getChildChildren ? getChildChildren(child) : undefined,
    }));

    if (items.length) {
      items.unshift({ title });
    }

    if (children.length > 3) {
      items.push(
        { divider: true },
        {
          label: gettext("More..."),
          link: getMoreLink(obj),
          target: "_self",
        }
      );
    }

    return items;
  };

const getRecentProjects = createRecentFunction({
  title: gettext("Projects"),
  getChildren: (app) => app.projs,
  getChildLink: (proj) => `${proj.url}tasks/`,
  getMoreLink: (app) => app.url,
});

const getRecentApplications = createRecentFunction({
  title: gettext("Applications"),
  getChildren: (bu) => bu.apps,
  getChildLink: (app) => app.url,
  getChildChildren: (app) => getRecentProjects(app),
  getMoreLink: (bu) => bu.url,
});

const getRecentBusinessUnits = createRecentFunction({
  title: gettext("Business Units"),
  getChildren: (businessUnits) => businessUnits,
  getChildLink: (bu) => bu.url,
  getChildChildren: (bu) => getRecentApplications(bu),
  getMoreLink: () => reverse("business_unit_list"),
});

const getDashboardItems = (payload) => {
  const { globalPerms: userPerms } = payload;

  if (userPerms && !userPerms.includes("view_analytics")) {
    return null;
  }

  const ENABLE_CUSTOM_REPORTING = waffle.flag_is_active(
    "ENABLE_CUSTOM_REPORTING"
  );
  const ENABLE_ADVANCED_DASHBOARDS = waffle.flag_is_active(
    "ENABLE_ADVANCED_REPORTING_DASHBOARDS"
  );
  const ENABLE_ADVANCED_REPORTING_TREND_REPORTS = waffle.flag_is_active(
    "ENABLE_ADVANCED_REPORTING_TREND_REPORTS"
  );

  const children = [];

  if (ENABLE_CUSTOM_REPORTING && !ENABLE_ADVANCED_DASHBOARDS) {
    children.push(
      {
        label: gettext("Dashboard Classic"),
        link: reverse("dashboard_classic"),
        target: "_self",
      },
      {
        label: gettext("Dashboard"),
        link: reverse("executive_overview_dashboard"),
        perm: (perms) => perms.viewAdvancedReports,
      }
    );
  }

  if (ENABLE_CUSTOM_REPORTING && ENABLE_ADVANCED_DASHBOARDS) {
    children.push({
      label: gettext("Dashboards"),
      link: reverse("dashboard_view_list"),
      highlight: (seen) => seen.includes("dashboard_view_list"),
      perm: (perms) => perms.viewAdvancedReports,
    });
  }

  if (!ENABLE_ADVANCED_DASHBOARDS && !ENABLE_CUSTOM_REPORTING) {
    children.push({
      label: gettext("Dashboard Classic"),
      link: reverse("dashboard_classic"),
      target: "_self",
    });
  }

  if (!ENABLE_SD_BLUEPRINT && ENABLE_CUSTOM_REPORTING) {
    children.push({
      label: ENABLE_ADVANCED_REPORTING_TREND_REPORTS
        ? gettext("Reports")
        : gettext("Advanced Reports"),
      link: reverse("reporting_queries"),
      perm: (perms) => perms.viewAdvancedReports,
    });
  }

  if (!ENABLE_CUSTOM_REPORTING && ENABLE_ADVANCED_DASHBOARDS) {
    children.push({
      label: gettext("Dashboard Classic"),
      link: reverse("dashboard_classic"),
      target: "_self",
    });
  }

  if (!ENABLE_SD_BLUEPRINT) {
    children.push({
      label: gettext("Global Reports"),
      link: reverse("project_reports_list"),
      perm: (perms) => perms.editReports,
    });

    if (payload.TRAINING_LICENSE) {
      children.push({
        label: gettext("Training Report"),
        link: reverse("training_reports"),
        perm: (perms) => perms.addUser,
      });
    }
  }

  const composeDashboardUrl = () => {
    if (ENABLE_SD_BLUEPRINT) return "dashboard_classic";
    if (ENABLE_CUSTOM_REPORTING && ENABLE_ADVANCED_DASHBOARDS)
      return "dashboard_view_list";
    return "dashboard_classic";
  };

  const dashboardUrl = composeDashboardUrl();

  if (ENABLE_SD_BLUEPRINT) {
    return {
      label: gettext("Dashboard"),
      link: reverse(dashboardUrl),
      target: "_self",
    };
  }

  return {
    label: gettext("Reporting"),
    link: reverse(dashboardUrl),
    target: "_self",
    children,
  };
};

const getProjectItems = () => {
  if (ENABLE_SD_BLUEPRINT) {
    return {
      label: gettext("Projects"),
      link: reverse("project_list", "blueprint", "projects"),
      perm: () => ENABLE_SD_BLUEPRINT,
    };
  }
};

const getRecentNavItems = (payload) => {
  const { bu_visibility: buVisibility } = payload;

  if (!buVisibility) {
    return null;
  }

  if (buVisibility.bu_visible) {
    return {
      label: gettext("Business Units"),
      link: reverse("business_unit_list"),
      target: "_self",
      children: getRecentBusinessUnits(payload.recentNavTree),
    };
  }
  if (buVisibility.bu_slug) {
    return {
      label: gettext("Applications"),
      link: reverse("application_list", buVisibility.bu_slug),
      target: "_self",
      children: getRecentApplications(payload.recentNavTree[0]),
    };
  }

  return null;
};

const getLibraryItems = () => ({
  label: gettext("Library"),
  link: reverse("profile_list"),
  children: [
    {
      label: gettext("Profiles"),
      link: reverse("profile_list"),
      perm: (perms) => perms.customizeContent,
    },
    {
      label: gettext("Project Survey"),
      link: reverse("survey_edit"),
      perm: (perms) => perms.customizeContent,
    },
    {
      label: gettext("Attributes"),
      link: reverse("library_attributes_list"),
      perm: (perms) =>
        perms.customizeContent && waffle.flag_is_active("ENABLE_ACORN"),
    },
    {
      label: gettext("Components"),
      link: reverse("library_shared_components_list"),
      perm: (perms) =>
        perms.customizeContent &&
        waffle.flag_is_active("ENABLE_SHARED_COMPONENTS"),
    },
    { label: gettext("Weaknesses"), link: reverse("library_weakness_list") },
    {
      label: gettext("Countermeasures"),
      link: reverse("library_standard_list"),
    },
    {
      label: gettext("Phases"),
      link: reverse("library_phase_list"),
      perm: (perms) => perms.customizeContent,
    },
    { label: gettext("Glossary"), link: reverse("term_list") },
    {
      label: gettext("Regulations"),
      link: reverse("library_regulation_list"),
    },
    {
      label: gettext("Content Packs"),
      beta: true,
      link: reverse("library_content_packs"),
      perm: (perms) =>
        perms.customizeContent &&
        waffle.switch_is_active("ENABLE_CONTENT_PACKS_UI"),
    },
    {
      label: gettext("Import/Export"),
      link: reverse("library_import_export"),
      perm: (perms) => perms.customizeContent,
      feature: (features) => features.libraryImport || features.libraryExport,
    },
  ],
});

const getManagementItems = () => ({
  label: gettext("Manage"),
  link: reverse("manage_user_list"),
  perm: (perms) => perms.addUser || perms.manageRiskPolicies,
  children: [
    {
      label: gettext("Users"),
      link: reverse("manage_user_list"),
      target: "_self",
      perm: (perms) => perms.addUser,
    },
    {
      label: gettext("Groups"),
      link: reverse("manage_group_list"),
      target: "_self",
      perm: (perms) => perms.addUser,
    },
    {
      label: gettext("Global Roles"),
      link: reverse("manage_global_roles_list"),
      target: "_self",
      perm: (perms) => perms.addUser,
    },
    {
      label: gettext("Project Roles"),
      link: reverse("manage_project_roles_list"),
      target: "_self",
      perm: (perms) => perms.addUser,
    },
    { divider: true },
    {
      label: gettext("Classifications"),
      link: reverse("manage_project_classifications_list"),
      target: "_self",
      perm: (perms) => perms.manageRiskPolicies && !ENABLE_SD_BLUEPRINT,
    },
    {
      label: gettext("Risk Policies"),
      link: reverse("manage_risk_policies_list"),
      target: "_self",
      perm: (perms) => perms.manageRiskPolicies && !ENABLE_SD_BLUEPRINT,
    },
  ],
});

export default (payload = {}) =>
  [
    getDashboardItems(payload),
    getProjectItems(),
    !ENABLE_SD_BLUEPRINT && getRecentNavItems(payload),
    !ENABLE_SD_BLUEPRINT && getLibraryItems(),
    getManagementItems(),
  ].filter((item) => item);
